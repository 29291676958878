
import React, { useContext, useState, useEffect } from 'react';

import Dropdown from '../../../Dropdown';
import FilterDropdown from '../../../FilterDropdown';

import AppContext from '../../context';
import styles from '../../../../styles/index.css';
import './style.css';
import { xIcon, activeArrow, disabledArrow } from '../../../../assets';
import {
  LifecycleStage,
  getRegisterType,
  REGISTER_TYPE
} from '../../../../utils/constants';
import constants from '../../../../styles/constants';

// import getCurrentCircuitType from './currentCircuitType';
// import CheckBox from '../CheckBox';

const localStyles = {};

const CheckBox = ({ isChecked, setIsChecked }) => {
  return (
    <div className='rf-checkboxContainer'>
      <div
        className={`rf-checkbox ${isChecked ? 'rf-checkboxChecked' : ''}`}
        onClick={() => setIsChecked((e) => !e)}
      />
    </div>
  );
};
const styleOverride = {};
// grid: if true, circuit type will be main
// production: if true, circuit type will be prod
// battery: if true, circuit type will be batt


function RowData({ label, data, dropdown = false }) {
  return dropdown ? (
    <div className='rf-row' style={{ marginBottom: -2 }}>
      <p  style={{width: '140px'}} className='rf-label'>{label}:</p>
      {data}
    </div>
  ) : (
    <div className='rf-row'>
      <p className='rf-label'>{label}:</p>
      <div className='rf-value' data-testid={`row-data-${label}`}>{data}</div>
    </div>
  );
}

const RegisterForm = ({
  deviceName = '',
  registerIndex = NaN,
  clampOptions = [],
  branchClampOptions = [],
  onSave,
  bleData = {},
  closeRegisterForm = () => null,
  modalVisible = false,
  mainClampFactor,
  branchClampFactor,
  circuitTypes, registerCategories,
  aliases, dropdownCircuitTypes, 
}) => {

  const { hubConfigs, publicService } = useContext(AppContext);

  const highOrStandardAccuracy = getRegisterType(registerIndex);
  const multiplierFactor = highOrStandardAccuracy === REGISTER_TYPE.HIGH_ACCURACY ? mainClampFactor : branchClampFactor;
  const clampOptionsFilteredByValidFactors = clampOptions

  const getCurrentCircuitType = (grid, production, battery) => {
    return circuitTypes.find(({grid: g, production: p, battery: b}) => g === grid && p === production && b === battery);
  }

  



  const setRegisterCategory = (category_name) => {
    const rc = registerCategories.filter(({ display_name }) => display_name === category_name)[0];
    publicService.getRegisterCategoryDetail(rc.uuid).then((rcdetailArr) => {
      const [rcdetail] = rcdetailArr;
      console.log(`GOT REgister Category Detail ${JSON.stringify(rcdetail)}`);
      setRegisterCatagoryDetail(rcdetail);
      const circuitType = circuitTypes.find(({_id}) => _id === rcdetail.circuit_type_id);
      if(circuitType) {
        console.log("circuitType.value :: circuitType.value ", circuitType.value);
        setNewCircuitType(circuitType.value);
      };
    });
    updateRegisterCategory(category_name);
  }

  const [registerCategoryDetail, setRegisterCatagoryDetail] = useState({});


  const branchClampOptionsFilteredByValidFactors = branchClampOptions
  const liveDataKeyNumber = registerIndex;
  const ps = publicService;
  const { installation: { registers = [] } = {} } = hubConfigs && hubConfigs[deviceName] ? hubConfigs[deviceName] : {};
  const {
    categories = [],
    label = '',
    clamp_definition = '',
    production = false,
    grid = false,
    battery = false,
    inverted = false,
    multiplier = 1,
  } = registers[registerIndex] || {};

  const [registerCategory, updateRegisterCategory] = useState(registerCategories.find(({ uuid }) => uuid === categories[0])?.display_name || '');
  const [newLabel, updateNewLabel] = useState(label);
  const newMultiplier = [
    { label: '1', value: 1},
    { label: '2', value: 2 },
  ];
  const [selectedMultiplier, updateSelectedMultiplier] = useState(multiplier);
  const currentCircuitType = getCurrentCircuitType(grid, production, battery);
  const [newCircuitType, updateNewCircuitType] = useState(currentCircuitType.value);  
  const [isMain, setIsMain] = useState(grid && !production && !battery);
  const setNewCircuitType = (a) => {
    const {grid, battery, production} = circuitTypes.filter(({ value }) => value === a)[0];
    // if main and not lineside production or for whatever reason battery
    if(grid && !production && !battery) {
      setIsMain(true);
      updateSelectedMultiplier(1);
    } else {
      setIsMain(false);
    }
    updateNewCircuitType(a);
  }
  const updateAlias = (sAlias) => {
    const aliasObj = aliases.find((alias) => alias.alias === sAlias);
    if (aliasObj) {
      let str = "";
      const x = registerCategories.find(rc => rc.uuid == aliasObj.register_category_uuid);
      if(x) str = x.display_name||"";
      setRegisterCategory(str);
    }
  }

  const [newClampDefinition, setNewClampDefinition] = useState(clamp_definition);
  const updateNewClampDefinition = (a) => {
    setNewClampDefinition(a);
  }
  const [newInverted, updateNewInverted] = useState(inverted);
  const [formSavingState, updateFormSavingState] = useState(
    LifecycleStage.Ready
  );

  const updateRegister = async () => {
    updateFormSavingState(LifecycleStage.Running);
    const clamp = branchClampOptionsFilteredByValidFactors.concat(clampOptionsFilteredByValidFactors).filter(
      ({ value }) => value === newClampDefinition
    )[0]?.niceName;
    let payload = {
      label: newLabel,
      clamp_definition: clamp,
      inverted: newInverted,
      multiplier: typeof selectedMultiplier === 'object' ? selectedMultiplier.value : selectedMultiplier,
    };

    const cType = circuitTypes.filter(({ value }) => value === newCircuitType)[0];
    payload = {
      ...payload,
      grid: cType.grid,
      battery: cType.battery,
      production: cType.production,
    };
  
    const data = {
      body: { ...payload },
      registerIndex,
      hubId: deviceName.replace('curb-', ''),
    };
    try {
      const updatedPackage = {
        registerIndex: data.registerIndex,
        hubId: data.hubId,
        body: {
          inverted: data.body.inverted,
          battery: data.body.battery,
          production: data.body.production,
          grid: data.body.grid,
          multiplier: data.body.multiplier,
          clamp_definition: data.body.clamp_definition,
        }
      };
      if(typeof updatedPackage.body.multiplier === "string") updatedPackage.body.multiplier=parseInt(updatedPackage.body.multiplier);
      if (data.body.label) updatedPackage.body.label = data.body.label;
      try {
        await Promise.all([
          ps.patchRegister(updatedPackage), 
          ps.updateRegisterCategory(registerIndex, deviceName, registerCategory)
        ]);
      } catch (e) {
        // this throws 400s when it works? the api hcs not us
      }
      onSave();
      updateFormSavingState(LifecycleStage.Finished);
    } catch (e) {
      updateFormSavingState(LifecycleStage.Error);
      throw new Error(e);
    }
  };
  if (Number.isNaN(Number(registerIndex))) {
    return null;
  }
  return (
    <div className='rf-centereddiv'>
      <div
        transparent={modalVisible.toString()}
        visible={modalVisible.toString()}
        style={{'visibility': modalVisible? 'block' : 'none', 'display': modalVisible? 'block' : 'none'}}
      >
        <div className='rf-greyOut'>
          <div className='rf-modaldiv'>
            <div
              data-testid='close-register-form'
              onClick={() => closeRegisterForm()}
              className='rf-closeIconContainer'
            >
              <img className='rf-xIcon' src={xIcon} />
            </div>
            <div className='rf-formContainer1'>
            <RowData
              label="Label"
              data={(
                <div className='rf-dropdownMover'>
                <FilterDropdown 
                  className='rf-textInput'
                  style={{
                    width: '236px',
                    textAlign: 'left',
                    color: constants.darkBlue,
                  }}
                  dataTestid='register-label'
                  placeholder="Register Label"
                  placeholderColor={constants.darkBlue}
                  current={newLabel}
                  onChange={(e) => {
                    for (const a of aliases) {
                          if (a.alias === e.target.value) {
                            updateAlias(a.alias);
                          }
                    }
                    updateNewLabel(e.target.value)
                  }}
                  optionList={aliases.map(({ alias }) => ({ label: alias, value: alias }))} 
              />
              </div>)} 
              />
                 <RowData
                    label="Category"
                    data={(
                      <div className='rf-dropdownMover'>
                      <Dropdown 
                        className='rf-textInput'
                        style={{
                          width: '236px',
                          textAlign: 'left',
                          color: constants.darkBlue,
                        }}
                        dataTestid='register-category'
                        placeholder="Register Category"
                        placeholderColor={constants.darkBlue}

                        current={registerCategory}
                        onChange={setRegisterCategory}
                        optionList={registerCategories.map(({ display_name }) => ({ label: display_name, value: display_name }))} 
                      />

              </div>)} 
              />
              {!isMain && (
                <>
                  <div style={{height: 30}} />
                  <RowData
                    label="Multiplier"
                    data={(
                      <div className='rf-dropdownMover'>

                        <Dropdown
                          dataTestid='multiplier-dropdown'
                          optionList={newMultiplier}
                          onChange={updateSelectedMultiplier}
                          current={`${selectedMultiplier}`}
                          styleOverride={styleOverride||{}}
                          iconColor={constants.white}
                        />
                      </div>
                    )}
                  />
                </>
              )}
              <>
                <div style={{height: 40}} />
                <RowData
                  label="Circuit Type"
                  data={(
                    <div className='rf-dropdownMover'>
                      {dropdownCircuitTypes.length>0 && <Dropdown
                        dataTestid='circuit-type-dropdown'
                        optionList={dropdownCircuitTypes}
                        onChange={updateNewCircuitType}
                        current={newCircuitType}
                        styleOverride={styleOverride}
                        iconColor={constants.white}
                      />}
                    </div>
                  )}
                  dropdown
                />
              </>
              
              {highOrStandardAccuracy === REGISTER_TYPE.HIGH_ACCURACY && clampOptions.length > 0 && (
                <RowData
                  label="CT Clamp"
                  data={(
                    <div className='rf-dropdownMover'>
                      <Dropdown
                        dataTestid='ct-clamp-dropdown'
                        optionList={clampOptionsFilteredByValidFactors.map(({ niceName, label, value }) => ({ label, value }))}
                        onChange={updateNewClampDefinition}
                        current={newClampDefinition}
                        styleOverride={styleOverride}
                        iconColor={constants.white}
                      />
                    </div>
                  )}
                  dropdown
                />
              )}
              {highOrStandardAccuracy === REGISTER_TYPE.STANDARD_ACCURACY && clampOptions.length > 0 && (
                <RowData
                  label="CT Clamp"
                  data={(
                    <div className='rf-dropdownMover'>
                      <Dropdown
                        dataTestid='ct-clamp-dropdown'
                        selectedpStyle={localStyles.selectedpStyle}
                        optionList={branchClampOptionsFilteredByValidFactors}
                        onChange={updateNewClampDefinition}
                        current={newClampDefinition}
                        styleOverride={styleOverride}
                        iconColor={constants.white}
                        containerStyle={localStyles.containerStyle}
                      />
                    </div>
                  )}
                  dropdown
                />
              )}
              <div className='rf-column'>
                {isMain && (
                  <RowData
                    label="Inverted"
                    data={(
                      <CheckBox
                        data-testid='inverted-checkbox'
                        isChecked={newInverted}
                        setIsChecked={() => updateNewInverted((e) => !e)}
                      />
                    )}
                  />
                )}
                {highOrStandardAccuracy === REGISTER_TYPE.HIGH_ACCURACY && (
                  <>
                    <RowData
                      label="Volts"
                      data={bleData[`v${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="Watts"
                      data={bleData[`p${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="Amps"
                      data={bleData[`i${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="PowerFactor"
                      data={bleData[`pf${liveDataKeyNumber}`]}
                    />
                    <RowData
                      label="Frequency"
                      data={bleData[`f${liveDataKeyNumber}`]}
                    />
                  </>
                )}
              </div>
              {highOrStandardAccuracy === REGISTER_TYPE.STANDARD_ACCURACY && (
                <div className='rf-column'>
                  <RowData
                    label="Watts"
                    data={bleData[`p${liveDataKeyNumber}`]}
                  />
                  <RowData
                    label="Amps"
                    data={bleData[`i${liveDataKeyNumber}`]}
                  />
                </div>
              )}
              {formSavingState === LifecycleStage.Running && (
                <div
                  className='rf-buttonContainer rf-disabled'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <p style={styles.mainButton}>Saving</p>
                  <img
                    className='rf-buttonArrow'
                    src={disabledArrow}
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  />
                </div>
              )}
              {formSavingState === LifecycleStage.Ready && (
                <div
                 className='rf-buttonContainer'
                 data-testid='save-button'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => updateRegister()}
                >
                  <button className={'rf-mainButton'}>Save</button>
                  {/* <img
                    src={activeArrow}
                    className='rf-buttonArrow'
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  /> */}
                </div>
              )}
              {formSavingState === LifecycleStage.Error && (
                <div
                  data-testid='after-error-save-button'
                  className='rf-buttonContainer'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  }}
                  onClick={() => updateRegister()}
                >
                  <p style={styles.mainButton}>
                    Error - Try Saving Again
                  </p>
                  <img
                    src={activeArrow}
                    className='rf-buttonArrow'
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  />
                </div>
              )}
              {formSavingState === LifecycleStage.Finished && (
                <div
                  className='rf-buttonContainer rf-disabled'
                  style={{
                    ...{
                      marginTop: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  }}
                  onClick={() => updateRegister()}
                  data-testid='after-success-save-button'
                >
                  <p className={'rf-mainButton'}>
                    Finished
                  </p>
                  <img
                    src={disabledArrow}
                    className='rf-buttonArrow'
                    style={{
                      ...{ marginTop: 19, marginRight: 20 },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
