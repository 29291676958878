import React, { useEffect } from 'react';
                
const FilterDropdown = ({
    className = '',
    style={},
    placeholder='',
    placeholderColor="",
    optionList,
    current,
    onChange,
    dataTestid='',
}) => {

  const options_ = Array.isArray(optionList) ?
    optionList 
    .map((option) => ({label:option.label, value:option.value})) 
    : [];
  const [options, setOptions] = React.useState(options_);
  const [value, setValue] = React.useState(current);


  useEffect(() => {
    setValue(current);
  }, [current]);

  useEffect(() => {
    if(JSON.stringify(options_) === JSON.stringify(options)) return;
    setOptions(options_);
    const k = current ? typeof current === "object" ? current.value : current : "";
    const val = `${k}`;
    let v = val;
    if (typeof val ==="object") v = val.value;
    setValue(v);
  }, [options_]);

  const onChangeLocal = (e) => {
    if(e.target.value.length===0){
      setValue("");
      onChange(e);
      const options_ = Array.isArray(optionList) ?
            optionList 
            .map((option) => ({label:option.label, value:option.value}))
            : [];
      setOptions(options_);
      return;
    }else {
      const filteredOptions = options.sort((a,b)=>a.label>b.label);
      setOptions(filteredOptions);
    }
    setValue(e.target.value);
    onChange(e);
  }

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: state.isSelected ? "#0052CB" : "#0052CB",
      backgroundColor: "#FFFFFF",
      fontSize: "0.9rem",
      cursor: "pointer",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #FFFFFF",
      borderRadius: "0.5rem",
      boxShadow: "none",
      color: "#0052CB",
      fontSize: "0.9rem",
      ":hover": {
        border: "1px solid #0052CB",
        color: "#06285e"

      },
      ":focus": {
        border: "1px solid #0052CB",
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#0052CB",
    })
  };

  const val = value ? typeof value === "object" ? value.value : value : ""; 
  return (
    <div className='location-label-dropdown-wrapper' data-testid={`${dataTestid}-div`}>
        <input 
            list={'list-of-suggestions-' + dataTestid}
            name="hub-label" 
            value={val} 
            onChange={onChangeLocal} 
            data-testid={`${dataTestid}-input`}
            placeholder={placeholder}
            style={style}
            className={className}
            placeholdercolor={placeholderColor}    
        />
        <datalist id={'list-of-suggestions-' + dataTestid}> 
            {!val && optionList.slice(0,10).map((option) => {
                return (<option key={option.value} value={option.value} />);
            })}
            {val && optionList.map((option) => {
                return (<option key={option.value} value={option.value} />);
            })}
        </datalist>
    </div>
  );
};


export default FilterDropdown;
