
import React, { useEffect } from 'react';

const Dropdown = ({optionList, current, onChange, dataTestid=''}) => {
  const [value, setValue] = React.useState(current);
  useEffect(() => {
    setValue(current);
  }, [current]);

  const onChangeLocal = (e) => {
    // propagate LocationID change
    console.log("onChangeLocal", e.target.value );
    setValue(e.target.value);
    onChange(e.target.value);
  }
  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: state.isSelected ? "#0052CB" : "#0052CB",
      backgroundColor: "#FFFFFF",
      fontSize: "0.9rem",
      cursor: "pointer",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #FFFFFF",
      borderRadius: "0.5rem",
      boxShadow: "none",
      color: "#0052CB",
      fontSize: "0.9rem",
      ":hover": {
        border: "1px solid #0052CB",
        color: "#06285e"

      },
      ":focus": {
        border: "1px solid #0052CB",
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#0052CB",
    })
  };

  return (
    <div className='location-dropdown-wrapper' data-testid={dataTestid}>
      <select 
        className="locationDropdown" 
        onChange={onChangeLocal} 
        id={dataTestid} 
        name={dataTestid}
        value={value}
      >
        {optionList.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};


export default Dropdown;



